import React, { useCallback, useEffect, useState } from 'react';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import { Link, useSearchParams } from 'react-router-dom';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {
  DressState,
  fetchAllDress,
  fetchAllDressNotLengthDress,
} from '../redux/slices/dresses';
import { IDressData } from "../redux/models/IDress";
import Dresses from "../components/dresses";
import Pagination from "@mui/material/Pagination";
import styles from "./style.module.scss";
import Search from '../components/search';

export default function Home() {
  const dispatch = useDispatch();

  const { dresses }: any = useSelector((state: DressState) => state.dresses);
  const isDressesLoading = dresses.status === "loading";
  const [currentCategoryTab, setCurrentCategoryTab] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [visablePagination, setVisablePagination] = useState(true);
  const [currentCategory, setCurrentCategory] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
      // @ts-ignore
      const params = Object.fromEntries([...searchParams]);
      const  { page } = params;
      setPage(Number(page) || 1);
      dispatch(fetchAllDress({...params}));
  }, [dispatch, searchParams, currentCategory, page, currentCategoryTab]);


  let pag = Math.ceil(dresses.totalPage / 12);
  let hr = pag <= 1 ? 1 : pag;

  const handleChange = (event: any, value: string) => {
    setCurrentCategoryTab(parseFloat(value));
    setPage(1);
    if (event.target.innerText === "ВСЕ") {
      setCurrentCategory("");
      setVisablePagination(true);
      return dispatch(fetchAllDress({ page: page }));
    }

    if (event.target.innerText === "НЕТ ЦВЕТА") {
      setCurrentCategory("");
      setVisablePagination(false);
      return dispatch(fetchAllDressNotLengthDress());
    }

    setCurrentCategory(event.target.innerText);
    setVisablePagination(true);
    return setSearchParams({category: event.target.innerText.toLowerCase() });
  };

  const handleChangePagination = useCallback((event: any, value: any) => {
    setPage(value);
    return setSearchParams({ page: value, category: currentCategory });
  }, [currentCategory, setSearchParams]);

  if (isDressesLoading) {
    return <span>Загрузка...</span>;
  }

  return (
    <>
      <Tabs
        style={{ marginBottom: 35 }}
        value={currentCategoryTab}
        aria-label="basic tabs example"
        onChange={handleChange}
        className={styles.paginationWrap}
      >
        <Tab label="Все" />
        <Tab label="Свадебные платья" />
        <Tab label="Вечерние платья" />
        <Tab label="Коктейльные платья" />
        <Tab label="Костюмы, комбинезоны" />
        <Tab label="Платья больших размеров" />
        <Tab label="Аксессуары" />
        <Tab label="Нет цвета" />
      </Tabs>
      <Box display="flex" sx={{ flexDirection: { xs: "column", md: "row"} }}>
        <Button
          component={Link}
          color="secondary"
          to="/add-dress"
          variant="contained"
          size="large"
          sx={{ mb: 2 }}
        >
          Добавить платишко
        </Button>
        <Search />
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ display: { xs: "none", sm: "table-cell" } }}>
                Фото
              </TableCell>
              <TableCell align="left">Название</TableCell>
              <TableCell align="left">Категория</TableCell>
              <TableCell align="left">Цена на мероприятие</TableCell>
              <TableCell align="left">Цена на покупку</TableCell>
              <TableCell align="left">Сортировка</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dresses?.items.map((item: IDressData) => (
              <Dresses key={item.id} data={item} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {hr > 1 && visablePagination && visablePagination && (
        <Stack spacing={2}>
          <div style={{ marginTop: "20px" }}>
            <Pagination
              onChange={handleChangePagination}
              count={hr}
              page={page || 1}
            />
          </div>
        </Stack>
      )}
    </>
  );
}
