import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { IDress, IDressData } from "../models/IDress";
import axios from "../../axios";

export interface DressState {
  dresses: IDress;
}

export const fetchAllDress: any = createAsyncThunk<any>(
  "dresses/fetchDresses",
  async (dataApi: any) => {
    const { category, page, title, limit } = dataApi;
    let catgory = "";
    let pageCurrent = "";
    let titlee = ''
    let limitt = ''

    if (limit) {
      limitt= `&limit=${limit}`;
    }
    if (category) {
      catgory = `&category=${category}`;
    }

    if (page) {
      pageCurrent = `&page=${page}`;
    }

    if(title) {
      titlee = `&title=${title}`;
    }

    const { data }: any = await axios.get(
      `/dress?admin=true${catgory}${pageCurrent}${titlee}${limitt}`
    );
    return data;
  }
);

export const fetchAllDressNotLengthDress: any = createAsyncThunk<any>(
  "dresses/fetchDresses",
  async () => {
    const { data }: any = await axios.get(`/dress?admin=true&limit=1000`);

    const filterData = data.data.filter(
      (item: IDressData) =>
        // @ts-ignore
        item.lengthDress?.includes("") || item.colors?.length === 0
    );

    const filterDataNew = { ...data, data: filterData };

    return filterDataNew;
  }
);

export const fetchRemoveDress: any = createAsyncThunk<any>(
  "dresses/fetchRemoveDresses",
  async (id) => {
    axios.delete(`/dress/${id}`);
  }
);

const initialState: DressState = {
  dresses: {
    items: [],
    totalPage: 0,
    status: "loading",
  },
};

const dressesSlice = createSlice({
  name: "dresses",
  initialState,
  reducers: {},
  extraReducers: {
    // Получение платьев
    [fetchAllDress.pending]: (state) => {
      state.dresses.items = [];
      state.dresses.status = "loading";
    },
    [fetchAllDress.fulfilled]: (state, action) => {
      state.dresses.items = action.payload.data;
      state.dresses.totalPage = action.payload.totalPage;
      state.dresses.status = "loaded";
    },
    [fetchAllDress.rejected]: (state) => {
      state.dresses.items = [];
      state.dresses.status = "error";
    },
    // Удаление платья
    [fetchRemoveDress.pending]: (state, action) => {
      state.dresses.items =
        state.dresses.items &&
        state.dresses.items.filter((items) => items.id !== action.meta.arg);
    },
  },
});

export default dressesSlice.reducer;
